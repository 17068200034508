/* ==========================================================================
   Form
   ========================================================================== */
/**
 * Settings
 */
/* ==========================================================================
   Settings.Defaults
   ========================================================================== */
/**
 * Breakpoints
 */
/**
 * Fonts
 */
/**
 * Project basic vars
 */
/**
 * Tools
 */
/* ==========================================================================
   Tools.Functions
   ========================================================================== */
/**
 * Function for rem mixin which removes units.
 */
/**
 * Basic fractions:
 */
/* ==========================================================================
   Tools.Mixins
   ========================================================================== */
/**
 * Generates column classes based on the defined breakpoints,
 * named with the convention `.u-sizexofy@breakpoint`, where
 * `x` is a subdivision of `y`.
 *
 * @include columns(3, 4);
 *
 * Generates:
 *  .u-size1of3@smallOnly
 *  .u-size1of3@smallUp
 *  .u-size1of3@smallDown
 *  .u-size1of3@mediumOnly
 *  .u-size1of3@mediumUp
 *  .u-size1of3@mediumDown
 *  .u-size1of3@largeUp
 *  .u-size1of3@largeDown
 *  .u-size2of3@smallOnly
 *  .u-size2of3@smallUp
 *  .u-size2of3@smallDown
 *  .u-size2of3@mediumOnly
 *  .u-size2of3@mediumUp
 *  .u-size2of3@mediumDown
 *  .u-size2of3@largeUp
 *  .u-size2of3@largeDown
 *
 *  (...)
 */
/**
 * Mixin for rem units within px fallback.
 * http://css-tricks.com/snippets/css/less-mixin-for-rem-font-sizing/
 *
 * .foo {
 *     @include rem(margin, 10, 20, 30, 40);
 * }
 *
 * .foo {
 *     margin: 160px 320px 480px 640px;
 *     margin: 10rem 20rem 30rem 40rem;
 * }
 *
 */
@media (min-width: 480px) {
  body::after {
    content: '{"small":{"value": "480px", "active": true}, "medium":{"value": "768px", "active": false}, "large":{"value": "1220px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

@media (min-width: 768px) {
  body::after {
    content: '{"small":{"value": "480px", "active": true}, "medium":{"value": "768px", "active": true}, "large":{"value": "1220px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

@media (min-width: 1220px) {
  body::after {
    content: '{"small":{"value": "480px", "active": true}, "medium":{"value": "768px", "active": true}, "large":{"value": "1220px", "active": true}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0;
  }
}

/**
 * Components
 */
/* ==========================================================================
   Components.Form
   ========================================================================== */
.c-Form {
  position: relative;
  z-index: 10;
}

.c-Form-legend {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 20px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .c-Form-legend {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.c-Form-item {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .c-Form-item {
    margin-bottom: 30px;
  }
}

.c-Form-label {
  display: block;
  margin-bottom: 5px;
  margin-left: 10px;
  font-weight: normal;
}

.c-Form-label--inline {
  position: relative;
  padding-left: 45px;
  margin: 0;
}

.c-Form-radio ~ .c-Form-label--inline:before, .c-Form-radio ~ .c-Form-label--inline:after,
.c-Form-checkbox ~ .c-Form-label--inline:before,
.c-Form-checkbox ~ .c-Form-label--inline:after {
  position: absolute;
}

.c-Form-radio ~ .c-Form-label--inline:before,
.c-Form-checkbox ~ .c-Form-label--inline:before {
  content: "";
  top: -11px;
  left: 0;
  width: 38px;
  height: 38px;
  border: 1px solid #d7d7d7;
}

.c-Form-radio ~ .c-Form-label--inline:before, .c-Form-radio ~ .c-Form-label--inline:after {
  border-radius: 50%;
}

.c-Form-radio ~ .c-Form-label--inline:after {
  top: -3px;
  left: 8px;
  width: 22px;
  height: 22px;
  background-color: #666;
}

.c-Form-checkbox ~ .c-Form-label--inline:after {
  top: -2px;
  left: 6px;
  width: 27px;
  height: 21px;
  background: url("../images/icons/check.svg") no-repeat 0 0;
  background-size: 100% 100%;
}

html.no-svg .c-Form-checkbox ~ .c-Form-label--inline:after {
  background-image: url("../images/icons/check.png");
}

.c-Form-radio:checked ~ .c-Form-label--inline:after,
.c-Form-checkbox:checked ~ .c-Form-label--inline:after {
  content: "";
}

@media (min-width: 768px) {
  .c-Form-label--inline {
    padding-left: 60px;
  }
  .c-Form-radio ~ .c-Form-label--inline:before,
  .c-Form-checkbox ~ .c-Form-label--inline:before {
    top: -6px;
  }
  .c-Form-radio ~ .c-Form-label--inline:after {
    top: 2px;
  }
  .c-Form-checkbox ~ .c-Form-label--inline:after {
    top: 3px;
  }
}

.c-Form-label--alt {
  font-size: 12px;
  font-size: 0.75rem;
  margin-left: 0;
  text-align: center;
}

@media (min-width: 768px) {
  .c-Form-label--alt {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.c-Form-input,
.c-Form-textarea {
  width: 100%;
  padding: 10px;
  margin: 0;
  border: 1px solid #d7d7d7;
  background-color: #fff;
}

.c-Form-block {
  position: relative;
  margin-bottom: 30px;
}

.c-Form-labelblock {
  display: block;
  padding: 60px 50% 30px 30px;
  cursor: pointer;
  font-weight: 600;
}

.c-Form-block--sprava .c-Form-labelblock {
  background-color: #f0f0f0;
}

.c-Form-block--aktivity .c-Form-labelblock {
  background-color: #c2e0bf;
}

.c-Form-block--helpdesk .c-Form-labelblock {
  background-color: #f7e3b0;
}

.c-Form-labelblock > span {
  position: relative;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-Form-labelblock {
    padding: 30px 30px 100px;
  }
  .c-Form-labelblock > span {
    display: block;
  }
}

.c-Form-blockInput {
  position: absolute;
  bottom: 20px;
  right: 30px;
  width: 45%;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-Form-blockInput {
    width: 80%;
    right: 10%;
  }
}

.c-Form-table {
  min-height: .01%;
  margin-bottom: 40px;
  overflow-x: auto;
}

.c-Form-table table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  overflow-x: auto;
}

.c-Form-table table th,
.c-Form-table table td {
  padding: 10px;
  border: 1px solid #d7d7d7;
  text-align: left;
}

.c-Form-table table td {
  vertical-align: top;
}

.c-Form-table table tbody tr:nth-child(odd) td {
  background-color: #f4f4f4;
}

.c-Form-table table tbody tr:hover td {
  background-color: #e9e7e7;
}

@media (min-width: 768px) {
  .c-Form-table {
    margin-bottom: 50px;
  }
  .c-Form-table table {
    table-layout: fixed;
  }
  .c-Form-table table th,
  .c-Form-table table td {
    padding: 15px 20px;
  }
  .c-Form-table table th {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.c-Form-button {
  padding: 10px 15px;
  border: 1px solid #51b048;
  background-color: #51b048;
  cursor: pointer;
}

.c-Form-button > span {
  display: inline-block;
  color: #fff;
  text-decoration: underline;
  vertical-align: middle;
}

.c-Form-button:hover {
  border-color: #333;
  background-color: transparent;
}

.c-Form-button:hover > span {
  color: #333;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-Form-button {
    padding: 20px 30px 20px 25px;
  }
}

@media (min-width: 1220px) {
  .c-Form-button {
    padding: 20px 30px 20px 25px;
  }
}

.c-Form-icon.icon-submit {
  width: 44px;
  height: 38px;
  margin-right: 5px;
  color: #fff;
}

.c-Form-button:hover .c-Form-icon.icon-submit {
  color: #333;
}

.c-Form-icon.icon-check {
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: none;
  width: 44px;
  height: 35px;
  margin-bottom: 5px;
  margin-left: -22px;
}

.c-Form-checkblock:checked ~ .c-Form-labelblock .c-Form-icon.icon-check {
  display: block;
}

.c-Form-block--sprava .c-Form-icon.icon-check {
  color: #33addf;
}

.c-Form-block--aktivity .c-Form-icon.icon-check {
  color: #51b048;
}

.c-Form-block--helpdesk .c-Form-icon.icon-check {
  color: #f59c10;
}

@media (min-width: 768px) {
  .c-Form-icon.icon-submit {
    margin-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .c-Form-icon.icon-check {
    bottom: 50%;
    right: 0;
    left: auto;
    margin-bottom: -17px;
  }
}
